import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

// Define action types as constants
const SET = 'set';
const SET_USER_DATA = 'setUserData';
const SET_REGISTER_DATA = 'setRegisterData';
const SET_CODE_HASH = 'setCodeHash';
const SET_ESIMS_DATA = 'setEsimsData';
const SET_BUNDLES_DATA = 'setBundlesData';
const SET_BUNDLES_AVAILABLE = 'setBundlesAvailable';
const SET_BUNDLES_AVAILABLE2 = 'setBundlesAvailable2';
const UPDATE_USER_WALLET = 'updateUserWallet';
const UPDATE_USER_IMAGE = 'updateUserImage';
const UPDATE_ESIM_NAME = 'UPDATE_ESIM_NAME';
const UPDATE_USER_DATA = 'updateUserData';
const LOGOUT = 'logout';

const initialState = {
  sidebarShow: true,
  userData: null,
  registerData: null,
  eSIMsData: null,
  bundlesData: null,
  bundlesAvailable: null,
  bundlesAvailable2: null,
  codeHash: ''
};
const updateEsimName = (updatedEsim) => ({
  type: UPDATE_ESIM_NAME,
  payload: updatedEsim,
});

const rootReducer = (state = initialState, { type, payload, ...rest }) => {
  switch (type) {
    case SET:
      return { ...state, ...rest };
    case SET_USER_DATA:
      return { ...state, userData: rest.userData };
    case SET_REGISTER_DATA:
      return { ...state, registerData: rest.registerData };
    case SET_ESIMS_DATA:
      return { ...state, eSIMsData: [...rest.eSIMsData] };
    case SET_BUNDLES_DATA:
      return { ...state, bundlesData: rest.bundlesData };
    case SET_BUNDLES_AVAILABLE:
      return { ...state, bundlesAvailable: rest.bundlesAvailable };
    case SET_BUNDLES_AVAILABLE2:
      return { ...state, bundlesAvailable2: rest.bundlesAvailable2 };
    case SET_CODE_HASH:
      return { ...state, codeHash: rest.codeHash };
    case UPDATE_USER_WALLET:
      return {
        ...state,
        userData: {
          ...state.userData,
          wallet: payload,
        },
      };
      case UPDATE_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          name: payload.first_name,
          last_name: payload.last_name,
          phone_number: payload.phone_number,
          company_name: payload.company_name,
        },
        };
      case UPDATE_USER_IMAGE:
        return {
          ...state,
          userData: {
            ...state.userData,
            profile_image: payload,
          },
        };
    case UPDATE_ESIM_NAME:
      return {
        ...state,
        eSIMsData: state.eSIMsData.map((esim) =>
          esim.id === payload.id ? { ...esim, name: payload.name } : esim
        ),
      };
    case LOGOUT:
      return { ...initialState, sidebarShow: state.sidebarShow }; // Reset to initial state  
    default:
      return state;
  }
};
const persistConfig = {
  key: 'root',
  storage,
  transforms: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export {persistor, store, updateEsimName };
